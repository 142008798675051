<template>
  <app-chip
    :icon="tag.icon ?? undefined"
    :size="size"
    :style="{
      backgroundColor: colors.bgColor,
      color: colors.textColor,
    }"
  >
    <slot>
      {{ tag.label }}
    </slot>
  </app-chip>
</template>

<script lang="ts" setup>
import { tagColors } from "~/business-areas/tag/composables/tag.utils";

const properties = defineProps<{
  tag: DatabaseTable<"tags">;
  size?: "small";
}>();

const colors = computed(() => tagColors(properties.tag));
</script>
